import React from "react";
import Layout from "../../../components/Layout";
import Seo from "../../../components/seo";
import ogIMage from "../../../images/og.png";

import { graphql, useStaticQuery } from "gatsby";
import Advisors from "../../../components/advisors";
import ContactUsBlock from "../../../components/ContactUsBlock";

const AdvisorsPage = ({ location }) => {
    const advisorsDetails = useStaticQuery(graphql`
        {
            allContentfulAdvisoryCategory {
                nodes {
                    id
                    categoryType
                    advisor {
                        name
                        designation
                        description {
                            description
                        }
                        linkedinUrl
                        avatar {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                quality: 100
                                placeholder: BLURRED
                                sizes: "250"
                            )
                            file {
                                url
                            }
                        }
                    }
                    metatitle
                    metadescription
                }
            }
        }
    `);
    const advisorData = advisorsDetails.allContentfulAdvisoryCategory.nodes[0];
    console.log(advisorData);
    const adseoTitle = advisorData.metatitle ? advisorData.metatitle : "Our Advisor";
    const adseoDesc = advisorData.metadescription
        ? advisorData.metadescription
        : "Meet the Sedin Advisors Team. Our Advisor team comprises of global next generation leaders coming together to address constantly evolving business challenge";
    return (
        <Layout>
            <Seo
                title={adseoTitle}
                description={adseoDesc}
                url={location.href}
                image={`https://sedintechnologies.com${ogIMage}`}
            />
            <section className="advisors-hero">
                <div className="container container--first flex flex-ai-c">
                    <div className="advisors-hero__text">
                        <p className="text-h3 text-clr-secondary text-fw-medium gap-2x">
                            Advisory Board
                        </p>
                        <h1 className="text-h2 text-clr-primary text-fw-medium">
                            Our advisor team comprises passionate, curious, creative and solution
                            driven business leaders.
                        </h1>
                    </div>
                </div>
            </section>
            <section>
                <div className="container container--py">
                    {advisorsDetails.allContentfulAdvisoryCategory.nodes.map((a, i) => {
                        return <Advisors category={a.categoryType} advisor={a.advisor} key={i} />;
                    })}
                </div>
            </section>
            <section className="about-contact">
                <div className="container container--last">
                    <ContactUsBlock />
                </div>
            </section>
        </Layout>
    );
};

export default AdvisorsPage;
