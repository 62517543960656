import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import linkedinIcon from "../images/advisor-linkedin.svg";

const Advisors = ({ category, advisor }) => {
    return (
        <div className="a-adv-wrap flex flex-jc-sb">
            <div className="a-adv-left-pane">
                {category && (
                    <h3 className="text-h3 text-fw-medium text-clr-primary">{category}</h3>
                )}
            </div>
            <div className="a-adv-right-pane">
                {advisor.length > 0 &&
                    advisor.map((adv, i) => {
                        return (
                            <div key={i} className=" a-adv-info  flex">
                                <div className="a-adv-img-wrp">
                                    <GatsbyImage
                                        image={adv.avatar.gatsbyImageData}
                                        alt={adv.name}
                                    />
                                </div>
                                <div className="a-adv-cnt-wrp">
                                    <p className="text-p2 text-fw-medium text-clr-primary a-adv-name">
                                        {adv.name}
                                    </p>
                                    <p className="text-p3 text-fw-regular gap-05x text-clr-primary a-team-designation">
                                        {adv.designation}
                                    </p>
                                    <p
                                        className="text-p3 text-fw-regular gap-1x text-clr-secondary"
                                        dangerouslySetInnerHTML={{
                                            __html: adv.description.description,
                                        }}
                                    ></p>
                                    {adv.linkedinUrl && (
                                        <>
                                            <div className="a-adv-linkedin-wrap">
                                                <a href={adv.linkedinUrl}>
                                                    <img src={linkedinIcon} alt="linkedin-icon" />
                                                </a>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default Advisors;
